import * as React from 'react'
import CockpitComponent from 'components/Cockpit/Component'
import Page from 'components/Page'
import { Cockpit } from 'api/cockpit/types'

type Props = {
	entry: Cockpit.Collection
}

const HomePage = ({ entry }: Props) => {
	if (!entry) return null

	return (
		<>
			{entry.content_da.map((item, index) => (
				<CockpitComponent key={index} component={item} />
			))}
		</>
	)
}

export default Page(HomePage, { pageType: 'Frontpage' })
