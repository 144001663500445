import * as React from 'react'
import api from 'api'
import { NextPageContext } from 'next'
import { Cockpit } from 'api/cockpit/types'
import HomePage from 'components/Cockpit/HomePage'
import HomePageDefault from 'components/HomePageDefault'

type Props = {
	entry: Cockpit.Collection
}

const Home = (props: Props) => {
	if (!props.entry || !props.entry.content_da.length) return <HomePageDefault />

	return <HomePage entry={props.entry} />
}

Home.getInitialProps = async (ctx: NextPageContext) => {
	try {
		// get latest modified published frontpage
		const page = await api.getCollection('frontpage-dk', {
			filter: { status: 'Published' },
			sort: { _modified: -1 },
			limit: 1,
		})
		const entry = page.entries[0] || null

		return {
			entry,
		}
	} catch (error) {
		error.ctx = ctx.err
		return {
			entry: null,
		}
	}
}

export default Home
