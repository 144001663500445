import * as React from 'react'
import { useRouter } from 'next/router'
import GA, { PageViewPrependableString } from 'utils/analytics'
import dataLayer from 'utils/analytics/dataLayer'
import { PageType } from 'utils/analytics/dataLayer.types'

type Props = {
	prependStringForAnalytics?: PageViewPrependableString
	pageType?: PageType
}

const Page = <P extends unknown>(
	Component: React.ComponentType<P>,
	pageProps: Props = {}
): React.ComponentType<P> => {
	return function WrappedComponent(props: any) {
		const router = useRouter()

		React.useEffect(() => {
			GA.trackPageview(router.asPath, pageProps.prependStringForAnalytics)
			if (pageProps.pageType) {
				// Be aware of events that rely on the pageview being called, and that are triggered immiediately upon mounting a view. This is a HOC and the effect will run after the children effects.
				dataLayer.push(pageProps.pageType)
			}
		}, [router.asPath])

		return <Component {...props} />
	}
}

export default Page
