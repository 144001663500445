import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Header from 'components/Header/header'
import api from 'api'
import { Source } from 'components/Image/Picture'
import { bunkerToS3Path, createSrcSet } from 'components/Image'
import logger from 'utils/logger'
import Page from 'components/Page'

const HomePageDefault = () => {
	const [image, setImage] = React.useState<Source[]>([])
	const { t } = useTranslation()

	React.useEffect(() => {
		api
			.getFrontpageImages()
			.then((res) => {
				const randomImage = res[Math.floor(Math.random() * res.length)]
				const imgUrl = bunkerToS3Path(randomImage.image_xxl)
				const set = createSrcSet(imgUrl, [
					{ width: 1920, height: 930 },
					{ width: 1680, height: 814 },
					{ width: 1440, height: 698 },
					{ width: 1280, height: 620 },
					{ width: 750, height: 861 },
					{ width: 375, height: 410 },
					{ width: 320, height: 350 },
				])
				setImage(set)
			})
			.catch((err) => logger.newrelicError(err))
	}, [])

	return (
		<Header
			showSearch={true}
			title={t('frontpage_search_title')}
			imageUrl={image[image.length - 1]?.url}
			srcSet={image}
			imageGradient={true}
			imgHeight={480}
		/>
	)
}

export default Page(HomePageDefault, { pageType: 'Frontpage' })
